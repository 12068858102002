import styled from "styled-components";

const Container = styled.section`
background-color: var(--branco);
margin: 2em;
border-radius: 16px;

height: 100%
`

export default Container;